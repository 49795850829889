enum GENERAL_ROUTES {
  HOME = '/',
  BASKET = '/baskets/basket',
  LIST = '/list',
  STORES = "/address/stores"
}

enum AUTH_ROUTES {
  AUTH = '/users/auth',
  LOGIN = '/users/auth/login',
  REGISTER = '/users/auth/register',
  FORGOT_PASSWORD = '/users/password/reset',
  EMAIL_SET_PRIMARY = '/users/email-set-primary/.+',
  CONFIRM_EMAIL = '/users/registration/account-confirm-email/.+',
  EASY_RETURN = '/users/auth/easy-return'
}

enum ACCOUNT_ROUTES {
  ACCOUNT = '/account',
  ACCOUNT_ADDRESS = '/account/address',
  ACCOUNT_CHANGE_PASSWORD = '/account/change-password',
  ACCOUNT_CONTACT = '/account/contact',
  ACCOUNT_HELP = '/account/help',
  ACCOUNT_ORDERS = '/users/orders',
  ACCOUNT_PROFILE = '/account/profile',
  ACCOUNT_WISHLIST = '/account/favourite-products/',
  ACCOUNT_VOUCHERS = '/account/vouchers'
}

enum ORDER_ROUTES {
  CHECKOUT = '/orders/checkout',
  CHECKOUT_COMPLETED = '/orders/completed'
}

enum FLATPAGE_ROUTES {
  CONTACT_US = '/contact-us',
  ABOUT_US = '/hakkimizda',
  FAQ = '/sss'
}

enum PRODUCT_ROUTES {}

export const ROUTES = {
  ...GENERAL_ROUTES,
  ...AUTH_ROUTES,
  ...ACCOUNT_ROUTES,
  ...ORDER_ROUTES,
  ...FLATPAGE_ROUTES,
  ...PRODUCT_ROUTES
};
